<template>
    <div v-if="showInvoice">
        <div style="font-family: arial !important; margin-top: 10px; margin-bottom: 10px;">
            <div><p style="font-size: 24px;">Vista previa de impresión de Factura Electronica</p></div>
            <br/>
            <div style="margin-bottom: 10px;">
                <button class="btn-action" style="font-size: 16px;" @click="Print()">
                    Imprimir
                </button>
                &nbsp;&nbsp;
                <button class="btn-action" style="font-size: 16px;" @click="Download()">
                    Descargar
                </button>
                &nbsp;&nbsp;
                <button :disabled="true" v-if="!hideQr" class="btn-action" style="font-size: 16px;" @click="sendMail()">
                    Enviar Correo
                </button>
                &nbsp;&nbsp;
                <button v-if="!hideQr" class="btn-action" style="font-size: 16px;" @click="downLoadJSON()">
                    Descargar JSON
                </button>
            </div>
            <div style="height: 30px;"><p style="margin: 5px;" v-if="loading">Cargando...</p></div>

        </div>
        <div style="font-family: arial !important; margin-top: 10px; margin-bottom: 10px;">
            <div v-if="mailLabel">
                {{ mailLabel }}
            </div>
        </div>
        <div class="book" style="margin: 0; padding: 0; background-color: rgb(105, 105, 105); font-family: arial !important; padding-top: 20px; padding-bottom: 20px; letter-spacing: 0.01px !important;">
                <div style="margin: 0 auto; width: 1000px;">
                    <div ref="dataprint" style="margin: 0 !important; padding: 0 !important;">
                        <!--div style="position: relative; width: 1000px; min-height: 1294px; background: white;"-->
                        <div v-for="(page, indexPage) in pages" :key="indexPage" style="position: relative; width: 1000px; min-height: 1293px; background: white;">
                            <!-- <hr/> -->
                            <div class="subpage" style="height: 1250px; padding-top: 20px; overflow: hidden; letter-spacing: 0.01px !important;">
                            
                                <div style="margin-left: 80px; margin-right: 50px;">
                                    <div v-if="hideQr" style="width: 100%; background-color: red;">
                                        <h3 style="color: white; text-align: center;">DOCUMENTO SIN VALIDEZ LEGAL</h3>
                                    </div>
                                    
                                    <div style="display: inline-block; width: 100%; vertical-align: top;">
                                        <div style="display: inline-block; margin: 0; padding: 0; width: 40%; vertical-align: top;">
                                            <table style="font-size: 10pt; border-collapse: collapse; width: 100%; display: inline-block; vertical-align: top; text-align: left;">
                                                <tr>
                                                    <td style="padding-right:20px; padding-bottom: 5px; color: white !important;">
                                                        <img v-if="allowedLogoBusiness" style="max-width: 330px !important; height: auto; max-height: 100px !important;" :src="'/gotera_logo.jpeg'" />
                                                    </td>
                                                </tr>
                                                <tr>
                                                </tr>
                                                <tr v-if="dataProps.header.businessName">
                                                <td style="padding-right:20px; padding-bottom: 5px; font-size: 12pt"><b> {{dataProps.header.businessName}}</b></td>
                                                </tr>
                                                <tr v-if="dataProps.header.businessComercialName">
                                                <td style="padding-right:20px; padding-bottom: 5px;">{{dataProps.header.businessComercialName}}</td>
                                                </tr>
                                                <tr v-if="dataProps.header.businessActivity">
                                                <td style="padding-right:20px; padding-bottom: 5px;">{{dataProps.header.businessActivity}}</td>
                                                </tr>
                                                <tr v-if="dataProps.header.businessAddress">
                                                <td style="padding-right:20px; padding-bottom: 5px;">{{dataProps.header.businessAddress}}</td>
                                                </tr>
                                                <tr v-if="dataProps.header.businessPhone">
                                                <td style="padding-right:20px; padding-bottom: 5px;">Tel. {{dataProps.header.businessPhone}}</td>
                                                </tr>
                                                <tr v-if="dataProps.header.businessMobile">
                                                <td style="padding-right:20px; padding-bottom: 5px;">Whatsapp. {{dataProps.header.businessMobile}}</td>
                                                </tr>
                                                <tr v-if="dataProps.header.businessEmail">
                                                <td style="padding-right:20px; padding-bottom: 5px;">{{dataProps.header.businessEmail}}</td>
                                                </tr>

                                                <!-- <tr v-if="dataProps.branchinfo">
                                                <td style="padding-right:20px; padding-bottom: 5px;" v-html="dataProps.branchinfo"></td>
                                                </tr> -->
                                                
                                                <tr>
                                                <td style="padding-right:20px; padding-bottom: 5px;">NIT: {{BillingNit}}</td>
                                                </tr>
                                                <tr>
                                                <td style="padding-right:20px; padding-bottom: 5px;">NRC: {{BillingNrc}}</td>
                                                </tr>
                                            </table>
                                        </div>

                                        <div style="display: inline-block; margin: 0; padding: 0; width: 60%; vertical-align: top; box-sizing: border-box;">
                                            <table style="font-size: 10pt; border-collapse: collapse; width: 100%; border: 0.5px solid; text-align: left">
                                                    <tr style="background-color: gray; width: 100% !important;">
                                                        <td style="padding-left: 10px; padding-right: 10px; padding-bottom: 5px; padding-top: 5px;  color: white !important; text-align: center;" colspan="4">
                                                            <!--{{ !hideQr ? 'DOCUMENTO TRIBUTARIO ELECTRÓNICO' : 'DOCUMENTO SIN VALIDEZ LEGAL' }} <br/> COMPROBANTE DE -->
                                                            {{ dataProps.header.invoiceType == 'FCF' ? "FACTURA CONSUMIDOR FINAL" : ""}}
                                                            {{ dataProps.header.invoiceType == 'CCF' ? "CRÉDITO FISCAL" : ""}}
                                                            {{ dataProps.header.invoiceType == 'FNS' ? "FACTURA SUJETO EXCLUIDO" : ""}}
                                                            {{ dataProps.header.invoiceType == 'FEX' ? "FACTURA DE EXPORTACIÓN" : ""}}
                                                            {{ dataProps.header.invoiceType == 'DNC' ? "NOTA DE CRÉDITO" : ""}}
                                                            {{ dataProps.header.invoiceType == 'DND' ? "NOTA DE DÉBITO" : ""}}
                                                            {{ dataProps.header.invoiceType == 'CDD' ? "DONACIÓN" : ""}}
                                                            {{ dataProps.header.invoiceType == 'DNR' ? "NOTA DE REMISIÓN" : ""}}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td style="padding-left: 10px; padding-right: 10px; padding-top: 10px; padding-bottom: 5px;"><b>Código generación:</b></td>
                                                        <td style="padding-left: 10px; padding-right: 10px; padding-bottom: 5px; padding-top: 10px;">{{dataProps.header.invoiceCodGen}}</td>
                                                    </tr>
                                                    <tr>
                                                        <td style="padding-left: 10px; padding-right: 10px; padding-bottom: 5px;"><b>Sello de recepción:</b></td>
                                                        <td style="padding-left: 10px; padding-right: 10px; padding-bottom: 5px;">{{dataProps.header.invoiceSelloRecepcion}}</td>
                                                    </tr>
                                                    <tr>
                                                        <td style="padding-left: 10px; padding-right: 10px; padding-bottom: 5px;"><b>Número de control:</b></td>
                                                        <td style="padding-left: 10px; padding-right: 10px; padding-bottom: 5px;">{{dataProps.header.invoiceNumControl}}</td>
                                                    </tr>
                                                    <tr>
                                                        <td style="padding-left: 10px; padding-right: 10px; padding-bottom: 5px;"><b>Fecha emisión:</b></td>
                                                        <td style="padding-left: 10px; padding-right: 10px; padding-bottom: 5px;">{{dataProps.header.invoiceDate}}</td>
                                                    </tr>
                                                    <tr>
                                                        <td style="padding-left: 10px; padding-right: 10px; padding-bottom: 5px;"><b>Correlativo factura:</b></td>
                                                        <td style="padding-left: 10px; padding-right: 10px; padding-bottom: 5px; ">{{dataProps.header.invoiceID}}</td>
                                                    </tr>
                                                    <tr v-if="!hideQr">
                                                        <td style="padding-left: 10px; padding-right: 10px; padding-bottom: 5px; text-align: center;" colspan="4">
                                                            <img ref="qrcode" :src="urlQr"/>
                                                        </td>
                                                    </tr>
                                            </table>
                                            <table style="font-size: 10pt; border-collapse: collapse; width: 100%;">
                                                <tr>
                                                    <td style="padding-left: 10px; padding-right: 10px; padding-top: 5px; text-align: left;"><b>Modelo de facturación:</b></td>
                                                    <td style="padding-left: 10px; padding-right: 10px; padding-top: 5px; text-align: left;" v-if="dataProps.header.invoiceModel == 1">Previo</td>
                                                    <td style="padding-left: 10px; padding-right: 10px; padding-top: 5px; text-align: left;" v-if="dataProps.header.invoiceModel == 2">Diferido</td>
                                                    <td style="padding-left: 10px; padding-right: 10px; padding-top: 5px; text-align: left;"><b>Tipo de transmisión:</b></td>
                                                    <td style="padding-left: 10px; padding-right: 10px; padding-top: 5px; text-align: left;" v-if="dataProps.header.invoiceOperation == 1">Normal</td>
                                                    <td style="padding-left: 10px; padding-right: 10px; padding-top: 5px; text-align: left;" v-if="dataProps.header.invoiceOperation == 2">Por contingencía</td>
                                                </tr>
                                                <tr>
                                                    
                                                </tr>
                                            </table>
                                        </div>
                                    </div>
                                    
                                    <br/>
                                    <hr/>
                                    <!--div style="width: 100%;" v-if="dataProps.branch.BusinessBillingNRC == '3213715' || dataProps.branch.BusinessBillingNRC == '2201050'">
                                        <table style="font-size: 10pt; border-collapse: collapse; width: 100%;">
                                            <tr>
                                                <td style="padding-left: 0px; padding-right: 0px; padding-bottom: 0px; text-align: center;"><img :src="announcement3213715" width="100%"/></td>
                                            </tr>
                                        </table>
                                    </div-->
                                    <div style="width: 100%;" v-if="dataProps.header.BranchBannerPath != null && dataProps.header.BranchBannerPath != '' && dataProps.header.BranchBannerPath != 'null'">
                                        <table style="font-size: 10pt; border-collapse: collapse; width: 100%;">
                                            <tr>
                                                <td style="padding-left: 0px; padding-right: 0px; padding-bottom: 0px; text-align: center;"><img style="max-height: 220px;" :src="dataProps.header.BranchBannerPath" width="100%"/></td>
                                            </tr>
                                        </table>
                                    </div>

                                    <br/>

                                    <div style="width: 100%; text-align: left;">
                                        <table style="font-size: 10pt; border-collapse: collapse; width: 100%;" v-if="dataProps.header.invoiceType != 'CCF'">
                                            <tr>
                                                <td style="padding-left: 10px; padding-right: 10px; padding-bottom: 5px; width: 200px !important;"><b>Razón social:</b></td>
                                                <td style="padding-left: 10px; padding-right: 10px; padding-bottom: 5px; width: 400px !important">{{dataProps.header.invoiceName}}</td>
                                            </tr>
                                            <tr>
                                                <td style="padding-left: 10px; padding-right: 10px; padding-bottom: 5px;"><b>Dirección:</b></td>
                                                <td style="padding-left: 10px; padding-right: 10px; padding-bottom: 5px;">{{dataProps.header.invoiceAddress}}</td>
                                            </tr>
                                            <tr>
                                                <td style="padding-left: 10px; padding-right: 10px; padding-bottom: 5px;"><b>DUI:</b></td>
                                                <td style="padding-left: 10px; padding-right: 10px; padding-bottom: 5px;">{{dataProps.header.invoiceNumDoc || ''}}</td>
                                                <td style="padding-left: 10px; padding-right: 10px; padding-bottom: 5px;"><b>NIT:</b></td>
                                                <td style="padding-left: 10px; padding-right: 10px; padding-bottom: 5px; width: 150px !important;">{{dataProps.header.invoiceNIT || ''}}</td>
                                            </tr>
                                             <tr>
                                                <td style="padding-left: 10px; padding-right: 10px; padding-bottom: 5px;"><b>Estado de la factura:</b></td>
                                                <td style="padding-left: 10px; padding-right: 10px; padding-bottom: 5px;">{{ dataProps.header.invoiceStatus == 'Cancelada' ? 'Al Contado' : dataProps.header.invoiceStatus }}</td>
                                                <td style="padding-left: 10px; padding-right: 10px; padding-bottom: 5px;"><b>Moneda:</b></td>
                                                <td style="padding-left: 10px; padding-right: 10px; padding-bottom: 5px;">{{dataProps.header.invoiceMoney || ''}}</td>
                                            </tr>
                                        </table>
                                        
                                        <table style="font-size: 10pt; border-collapse: collapse; width: 100%;" v-else>
                                            <tr>
                                                <td style="padding-left: 10px; padding-right: 10px; padding-bottom: 5px; width: 200px !important;"><b>Razón social:</b></td>
                                                <td style="padding-left: 10px; padding-right: 10px; padding-bottom: 5px; width: 400px !important;">{{dataProps.header.invoiceName}}</td>
                                            </tr>
                                            <tr>
                                                <td style="padding-left: 10px; padding-right: 10px; padding-bottom: 5px;"><b>Dirección:</b></td>
                                                <td style="padding-left: 10px; padding-right: 10px; padding-bottom: 5px;">{{dataProps.header.invoiceAddress}}</td>
                                            </tr>
                                            <tr>
                                                <td style="padding-left: 10px; padding-right: 10px; padding-bottom: 5px;"><b>NRC:</b></td>
                                                <td style="padding-left: 10px; padding-right: 10px; padding-bottom: 5px;">{{dataProps.header.invoiceNRC}}</td>
                                                <!-- <td style="padding-left: 10px; padding-right: 10px; padding-bottom: 5px;"><b>Entidad:</b></td>
                                                <td style="padding-left: 10px; padding-right: 10px; padding-bottom: 5px;">{{dataProps.header.invoiceEntity}}</td> -->
                                            </tr>
                                            <tr>
                                                <td style="padding-left: 10px; padding-right: 10px; padding-bottom: 5px;"><b>Actividad económica:</b></td>
                                                <td style="padding-left: 10px; padding-right: 10px; padding-bottom: 5px;">{{dataProps.header.invoiceEntityActivity}}</td>
                                                <td style="padding-left: 10px; padding-right: 10px; padding-bottom: 5px;"><b>NIT:</b></td>
                                                <td style="padding-left: 10px; padding-right: 10px; padding-bottom: 5px; width: 150px !important;">{{dataProps.header.invoiceNIT}}</td>
                                            </tr>
                                            <tr>
                                                <td style="padding-left: 10px; padding-right: 10px; padding-bottom: 5px;"><b>Estado de la factura:</b></td>
                                                <td style="padding-left: 10px; padding-right: 10px; padding-bottom: 5px;">{{ dataProps.header.invoiceStatus == 'Cancelada' ? 'Al Contado' : dataProps.header.invoiceStatus }}</td>
                                                <td style="padding-left: 10px; padding-right: 10px; padding-bottom: 5px;"><b>Moneda:</b></td>
                                                <td style="padding-left: 10px; padding-right: 10px; padding-bottom: 5px;">{{dataProps.header.invoiceMoney || ''}}</td>
                                            </tr>
                                        </table>
                                    </div>
                                    <template v-if="dataProps.header.invoiceType == 'DNC' || dataProps.header.invoiceType == 'DND'">
                                        
                                        <br/> 
                                        <div style="width: 100%;">
                                            <table style="width: 100% !important; font-size: 11px; border-collapse: collapse; border: 1px solid black;">
                                                <tr style="background: gray;">
                                                    <td colspan="3" style="text-align: center; color: white;">DOCUMENTOS RELACIONADOS</td>
                                                </tr>
                                                <tr>
                                                    <td style="border: 1px solid; padding-left: 2px; width: 35px;">Tipo de Documento</td>
                                                    <td style="border: 1px solid; padding-left: 2px; width: 35px;">Nº de Documento</td>
                                                    <td style="border: 1px solid; padding-left: 2px; width: 35px;">Fecha de Documento</td>
                                                </tr>

                                                <tr v-for="(item, index) in dataProps.header.invoiceDocRelated" :key="index">
                                                    <td style="border-left: 1px solid; border-right: 1px solid;">
                                                        {{ item.tipoDocumento == "03" ? "Comprobante de Crédito Fiscal" : "" }}
                                                    </td>
                                                    <td style="border-left: 1px solid; border-right: 1px solid;">{{ item.numeroDocumento }}</td>
                                                    <td style="border-left: 1px solid; border-right: 1px solid;">{{ item.fechaEmision }}</td>
                                                </tr>
                                            </table>
                                        </div>
                                    </template>

                                    <br/>
                                    <div style="width: 100%;">
                                        <table style="width: 100% !important; font-size: 11px; border-collapse: collapse; border: 1px solid black;">
                                            <tr style="background: gray;">
                                                <td colspan="11" style="text-align: center; color: white;">CUERPO DEL DOCUMENTO</td>
                                            </tr>

                                            <tr>
                                                <td style="border: 1px solid; padding-left: 2px; width: 35px;">No.<br/>Item</td>
                                                <td style="border: 1px solid; padding-left: 2px; width: 35px;">Tipo<br/>Item</td>
                                                <td style="border: 1px solid; padding-left: 2px; width: 35px;">Cant.</td>
                                                <td style="border: 1px solid; padding-left: 2px; width: 95px;">Código</td>
                                                <td style="border: 1px solid; padding-left: 2px;">Descripcion</td>
                                                <td style="border: 1px solid; padding-left: 2px; width: 65px;">Precio<br/>unitario {{dataProps.header.invoiceMoney}}</td>
                                                <td style="border: 1px solid; padding-left: 2px; width: 65px;">Descuento {{dataProps.header.invoiceMoney}}</td>
                                                <td v-if="dataProps.header.invoiceType != 'FEX'" style="border: 1px solid; padding-left: 2px; width: 65px;">Ventas<br/>exentas {{dataProps.header.invoiceMoney}}</td>
                                                <td v-if="dataProps.header.invoiceType != 'FEX'" style="border: 1px solid; padding-left: 2px; width: 65px;">Ventas no<br/>sujetas {{dataProps.header.invoiceMoney}}</td>
                                                <td v-if="dataProps.header.invoiceType == 'FEX'" style="border: 1px solid; padding-left: 2px; width: 65px;">Otros montos no afectos {{dataProps.header.invoiceMoney}}</td>
                                                <td v-if="dataProps.header.invoiceType != 'FEX'" style="border: 1px solid; padding-left: 2px; width: 65px;">Ventas <br/>gravadas {{dataProps.header.invoiceMoney}}</td>
                                                <td v-if="dataProps.header.invoiceType == 'FEX'" style="border: 1px solid; padding-left: 2px; width: 65px;">Ventas <br/>afectas {{dataProps.header.invoiceMoney}}</td>
                                            </tr>
                                            <!--tr v-for="(item, index) in dataProps.body" :key="index"-->
                                            <tr v-for="(item, index) in valuesRows[indexPage]" :key="index">
                                                <td style="border-left: 1px solid; border-right: 1px solid;">{{ typePrint == 0 ? (index+1)+(40*indexPage) : (index+1)+(30*indexPage) }}</td>
                                                <td style="border-left: 1px solid; border-right: 1px solid;">3</td>
                                                <td style="border-left: 1px solid; border-right: 1px solid;">{{ item.cantidad }}</td>
                                                <td style="border-left: 1px solid; border-right: 1px solid;">{{ item.codigo }}</td>
                                                <td style="border-left: 1px solid; border-right: 1px solid; text-align: left;">{{ item.descripcion }}</td>
                                                <td style="border-left: 1px solid; border-right: 1px solid; text-align: right;">{{ parseFloat(item.precioUni).toFixed(4) }}</td>
                                                <td style="border-left: 1px solid; border-right: 1px solid; text-align: right;">{{ numberWithCommas(parseFloat(item.montoDescu).toFixed(2)) }}</td>
                                                <td v-if="dataProps.header.invoiceType != 'FEX'" style="border-left: 1px solid; border-right: 1px solid; text-align: right;">{{ numberWithCommas(parseFloat(item.ventaExenta).toFixed(2)) }}</td>
                                                <td v-if="dataProps.header.invoiceType != 'FEX'" style="border-left: 1px solid; border-right: 1px solid; text-align: right;">{{ numberWithCommas(parseFloat(item.ventaNoSuj).toFixed(2)) }}</td>
                                                <td v-if="dataProps.header.invoiceType == 'FEX'" style="border-left: 1px solid; border-right: 1px solid; text-align: right;">{{ numberWithCommas(parseFloat(0).toFixed(2)) }}</td>
                                                <td style="border-left: 1px solid; border-right: 1px solid; text-align: right;">{{ numberWithCommas(parseFloat(item.ventaGravada).toFixed(2)) }}</td>
                                            </tr>

                                            <tr v-if="indexPage == (numPages-1)">
                                                <td v-if="dataProps.header.invoiceType == 'DNC' || dataProps.header.invoiceType == 'DND'" colspan="6" style="text-align: center; border: 1px solid;">
                                                    <b>Valor en letras: </b> {{GetnumberToText(dataProps.resume.montoTotalOperacion)}}
                                                </td>
                                                <td v-else colspan="6" style="text-align: center; border: 1px solid;">
                                                    <b>Valor en letras: </b> {{GetnumberToText(dataProps.resume.totalPagar)}}
                                                </td>

                                                <td colspan="5" style="border: 1px solid;">
                                                    <table style="width: 100% !important; font-size: 11px; text-align: right;">

                                                        <template v-if="dataProps.header.invoiceType == 'CCF'">
                                                            <tr>
                                                                <td>Sub total ventas gravadas</td>
                                                                <td>{{ dataProps.header.invoiceMoney }}</td>
                                                                <td style="text-align: right; width: 50px;">{{numberWithCommas(parseFloat(dataProps.resume.subTotalVentas).toFixed(2))}}</td>
                                                            </tr>   
                                                            <tr>
                                                                <td>Total descuentos</td>
                                                                <td>{{dataProps.header.invoiceMoney}}</td>
                                                                <td style="text-align: right; width: 50px;">{{numberWithCommas(parseFloat(dataProps.resume.totalDescu).toFixed(2))}}</td>
                                                            </tr>   
                                                            <tr>
                                                                <td>IVA</td>
                                                                <td>{{dataProps.header.invoiceMoney}}</td>
                                                                <td style="text-align: right; width: 50px;">{{numberWithCommas(parseFloat(dataProps.resume.totalIva).toFixed(2))}}</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Sub total</td>
                                                                <td>{{dataProps.header.invoiceMoney}}</td>
                                                                <td style="text-align: right; width: 50px;">{{numberWithCommas(parseFloat(dataProps.resume.montoTotalOperacion).toFixed(2))}}</td>
                                                            </tr>
                                                            <tr>
                                                                <td>IVA Retenido</td>
                                                                <td>{{dataProps.header.invoiceMoney}}</td>
                                                                <td style="text-align: right; width: 50px;">{{numberWithCommas(parseFloat(dataProps.resume.ivaRete1).toFixed(2))}}</td>
                                                            </tr>
                                                            <tr>
                                                                <td>IVA Percibido</td>
                                                                <td>{{dataProps.header.invoiceMoney}}</td>
                                                                <td style="text-align: right; width: 50px;">{{numberWithCommas(parseFloat(dataProps.resume.ivaPerce1 || dataProps.resume.ivaPerci1).toFixed(2))}}</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Impuesto sobre la renta</td>
                                                                <td>{{dataProps.header.invoiceMoney}}</td>
                                                                <td style="text-align: right; width: 50px;">{{numberWithCommas(parseFloat(dataProps.resume.reteRenta).toFixed(2))}}</td>
                                                            </tr>
                                                            <tr v-if="dataProps.header.invoiceType === 'FEX'">
                                                                <td>Flete</td>
                                                                <td>{{dataProps.header.invoiceMoney}}</td>
                                                                <td style="text-align: right; width: 50px;">{{numberWithCommas(parseFloat(dataProps.resume.flete).toFixed(2))}}</td>
                                                            </tr>
                                                            <tr v-if="dataProps.header.invoiceType === 'FEX'">
                                                                <td>Seguro</td>
                                                                <td>{{dataProps.header.invoiceMoney}}</td>
                                                                <td style="text-align: right; width: 50px;">{{numberWithCommas(parseFloat(dataProps.resume.seguro).toFixed(2))}}</td>
                                                            </tr>
                                                            <tr v-if="parseFloat(dataProps.resume.fovial) >  0">
                                                                <td>Fovial</td>
                                                                <td>{{dataProps.header.invoiceMoney}}</td>
                                                                <td style="text-align: right; width: 50px;">{{numberWithCommas(parseFloat(dataProps.resume.fovial).toFixed(2))}}</td>
                                                            </tr>
                                                            <tr v-if="parseFloat(dataProps.resume.fovial) >  0">
                                                                <td>Cotrans</td>
                                                                <td>{{dataProps.header.invoiceMoney}}</td>
                                                                <td style="text-align: right; width: 50px;">{{numberWithCommas(parseFloat(dataProps.resume.cotrans).toFixed(2))}}</td>
                                                            </tr>
                                                            <tr style="font-size: 12px;">
                                                                <td><b>Total</b></td>
                                                                <td><b>{{dataProps.header.invoiceMoney}}</b></td>
                                                                <td style="text-align: right; width: 50px;"><b>{{numberWithCommas(parseFloat(dataProps.resume.totalPagar).toFixed(2))}}</b></td>
                                                            </tr>                                                          
                                                        </template>

                                                        <template v-if="dataProps.header.invoiceType == 'FCF' || dataProps.header.invoiceType == 'CDD' || dataProps.header.invoiceType == 'DNR'">
                                                            <tr>
                                                                <td>Sub total de ventas</td>
                                                                <td>{{ dataProps.header.invoiceMoney }}</td>
                                                                <td style="text-align: right; width: 50px;">{{numberWithCommas(parseFloat(dataProps.resume.subTotalVentas).toFixed(2))}}</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Total descuentos</td>
                                                                <td>{{dataProps.header.invoiceMoney}}</td>
                                                                <td style="text-align: right; width: 50px;">{{numberWithCommas(parseFloat(dataProps.resume.totalDescu).toFixed(2))}}</td>
                                                            </tr>
                                                            <tr>
                                                                <td>IVA Retenido</td>
                                                                <td>{{dataProps.header.invoiceMoney}}</td>
                                                                <td style="text-align: right; width: 50px;">{{dataProps.resume.ivaRete1 ? numberWithCommas(parseFloat(dataProps.resume.ivaRete1).toFixed(2)) : numberWithCommas(parseFloat(0).toFixed(2))}}</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Impuesto sobre la renta</td>
                                                                <td>{{dataProps.header.invoiceMoney}}</td>
                                                                <td style="text-align: right; width: 50px;">{{dataProps.resume.reteRenta ? numberWithCommas(parseFloat(dataProps.resume.reteRenta).toFixed(2)) : numberWithCommas(parseFloat(0).toFixed(2))}}</td>
                                                            </tr>
                                                            <tr v-if="dataProps.header.invoiceType === 'FEX'">
                                                                <td>Flete</td>
                                                                <td>{{dataProps.header.invoiceMoney}}</td>
                                                                <td style="text-align: right; width: 50px;">{{numberWithCommas(parseFloat(dataProps.resume.flete).toFixed(2))}}</td>
                                                            </tr>
                                                            <tr v-if="dataProps.header.invoiceType === 'FEX'">
                                                                <td>Seguro</td>
                                                                <td>{{dataProps.header.invoiceMoney}}</td>
                                                                <td style="text-align: right; width: 50px;">{{numberWithCommas(parseFloat(dataProps.resume.seguro).toFixed(2))}}</td>
                                                            </tr>
                                                            <tr v-if="parseFloat(dataProps.resume.fovial) >  0">
                                                                <td>Fovial</td>
                                                                <td>{{dataProps.header.invoiceMoney}}</td>
                                                                <td style="text-align: right; width: 50px;">{{numberWithCommas(parseFloat(dataProps.resume.fovial).toFixed(2))}}</td>
                                                            </tr>
                                                            <tr v-if="parseFloat(dataProps.resume.fovial) >  0">
                                                                <td>Cotrans</td>
                                                                <td>{{dataProps.header.invoiceMoney}}</td>
                                                                <td style="text-align: right; width: 50px;">{{numberWithCommas(parseFloat(dataProps.resume.cotrans).toFixed(2))}}</td>
                                                            </tr>
                                                            <tr v-if="dataProps.header.invoiceType == 'DNR'" style="font-size: 12px;">
                                                                <td><b>Total</b></td>
                                                                <td><b>{{dataProps.header.invoiceMoney}}</b></td>
                                                                <td style="text-align: right; width: 50px;"><b>{{numberWithCommas(parseFloat(dataProps.resume.montoTotalOperacion).toFixed(2))}}</b></td>
                                                            </tr>
                                                            <tr v-else style="font-size: 12px;">
                                                                <td><b>Total</b></td>
                                                                <td><b>{{dataProps.header.invoiceMoney}}</b></td>
                                                                <td style="text-align: right; width: 50px;"><b>{{numberWithCommas(parseFloat(dataProps.resume.totalPagar).toFixed(2))}}</b></td>
                                                            </tr>
                                                        </template>

                                                        <template v-if="dataProps.header.invoiceType == 'FEX'">
                                                            <tr>
                                                                <td>Total de Operaciones Afectas</td>
                                                                <td>{{ dataProps.header.invoiceMoney }}</td>
                                                                <td style="text-align: right; width: 50px;">{{numberWithCommas(parseFloat(dataProps.resume.subTotalVentas || dataProps.resume.totalGravada).toFixed(2))}}</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Monto global de Desc., Rebajas de operaciones afectas</td>
                                                                <td>{{dataProps.header.invoiceMoney}}</td>
                                                                <td style="text-align: right; width: 50px;">{{numberWithCommas(parseFloat(dataProps.resume.totalDescu).toFixed(2))}}</td>
                                                            </tr>
                                                            <tr v-if="dataProps.header.invoiceType === 'FEX'">
                                                                <td>Flete</td>
                                                                <td>{{dataProps.header.invoiceMoney}}</td>
                                                                <td style="text-align: right; width: 50px;">{{numberWithCommas(parseFloat(dataProps.resume.flete).toFixed(2))}}</td>
                                                            </tr>
                                                            <tr v-if="dataProps.header.invoiceType === 'FEX'">
                                                                <td>Seguro</td>
                                                                <td>{{dataProps.header.invoiceMoney}}</td>
                                                                <td style="text-align: right; width: 50px;">{{numberWithCommas(parseFloat(dataProps.resume.seguro).toFixed(2))}}</td>
                                                            </tr>
                                                            <tr v-if="parseFloat(dataProps.resume.fovial) >  0">
                                                                <td>Fovial</td>
                                                                <td>{{dataProps.header.invoiceMoney}}</td>
                                                                <td style="text-align: right; width: 50px;">{{numberWithCommas(parseFloat(dataProps.resume.fovial).toFixed(2))}}</td>
                                                            </tr>
                                                            <tr v-if="parseFloat(dataProps.resume.fovial) >  0">
                                                                <td>Cotrans</td>
                                                                <td>{{dataProps.header.invoiceMoney}}</td>
                                                                <td style="text-align: right; width: 50px;">{{numberWithCommas(parseFloat(dataProps.resume.cotrans).toFixed(2))}}</td>
                                                            </tr>
                                                            <tr style="font-size: 12px;">
                                                                <td><b>Monto Total de la Operación</b></td>
                                                                <td><b>{{dataProps.header.invoiceMoney}}</b></td>
                                                                <td style="text-align: right; width: 50px;"><b>{{numberWithCommas(parseFloat(dataProps.resume.totalPagar).toFixed(2))}}</b></td>
                                                            </tr>
                                                        </template>

                                                        <template  v-if="dataProps.header.invoiceType == 'FNS'">
                                                            <tr>
                                                                <td>Sub total de ventas</td>
                                                                <td>{{ dataProps.header.invoiceMoney }}</td>
                                                                <td style="text-align: right; width: 50px;">{{numberWithCommas(parseFloat(dataProps.resume.subTotalVentas).toFixed(2))}}</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Total descuentos</td>
                                                                <td>{{dataProps.header.invoiceMoney}}</td>
                                                                <td style="text-align: right; width: 50px;">{{numberWithCommas(parseFloat(dataProps.resume.totalDescu).toFixed(2))}}</td>
                                                            </tr>
                                                            <tr>
                                                                <td>IVA Retenido</td>
                                                                <td>{{dataProps.header.invoiceMoney}}</td>
                                                                <td style="text-align: right; width: 50px;">{{numberWithCommas(parseFloat(dataProps.resume.ivaRete1).toFixed(2))}}</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Impuesto sobre la renta</td>
                                                                <td>{{dataProps.header.invoiceMoney}}</td>
                                                                <td style="text-align: right; width: 50px;">{{numberWithCommas(parseFloat(dataProps.resume.reteRenta).toFixed(2))}}</td>
                                                            </tr>
                                                            <tr v-if="dataProps.header.invoiceType === 'FEX'">
                                                                <td>Flete</td>
                                                                <td>{{dataProps.header.invoiceMoney}}</td>
                                                                <td style="text-align: right; width: 50px;">{{numberWithCommas(parseFloat(dataProps.resume.flete).toFixed(2))}}</td>
                                                            </tr>
                                                            <tr v-if="dataProps.header.invoiceType === 'FEX'">
                                                                <td>Seguro</td>
                                                                <td>{{dataProps.header.invoiceMoney}}</td>
                                                                <td style="text-align: right; width: 50px;">{{numberWithCommas(parseFloat(dataProps.resume.seguro).toFixed(2))}}</td>
                                                            </tr>
                                                            <tr v-if="parseFloat(dataProps.resume.fovial) >  0">
                                                                <td>Fovial</td>
                                                                <td>{{dataProps.header.invoiceMoney}}</td>
                                                                <td style="text-align: right; width: 50px;">{{numberWithCommas(parseFloat(dataProps.resume.fovial).toFixed(2))}}</td>
                                                            </tr>
                                                            <tr v-if="parseFloat(dataProps.resume.fovial) >  0">
                                                                <td>Cotrans</td>
                                                                <td>{{dataProps.header.invoiceMoney}}</td>
                                                                <td style="text-align: right; width: 50px;">{{numberWithCommas(parseFloat(dataProps.resume.cotrans).toFixed(2))}}</td>
                                                            </tr>
                                                            <tr style="font-size: 12px;">
                                                                <td><b>Total</b></td>
                                                                <td><b>{{dataProps.header.invoiceMoney}}</b></td>
                                                                <td style="text-align: right; width: 50px;"><b>{{numberWithCommas(parseFloat(dataProps.resume.totalPagar).toFixed(2))}}</b></td>
                                                            </tr>
                                                        </template>

                                                        <template v-if="dataProps.header.invoiceType == 'DNC' || dataProps.header.invoiceType == 'DND'">
                                                            <tr>
                                                                <td>Sub total de ventas</td>
                                                                <td>{{dataProps.header.invoiceMoney}}</td>
                                                                <td style="text-align: right; width: 50px;">{{numberWithCommas(parseFloat(dataProps.resume.subTotalVentas).toFixed(2))}}</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Monto global Des., Rebajas y otros a ventas no sujetas</td>
                                                                <td>{{dataProps.header.invoiceMoney}}</td>
                                                                <td style="text-align: right; width: 50px;">{{numberWithCommas(parseFloat(dataProps.resume.descuNoSuj).toFixed(2))}}</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Monto global Des., Rebajas y otros a ventas exentas</td>
                                                                <td>{{dataProps.header.invoiceMoney}}</td>
                                                                <td style="text-align: right; width: 50px;">{{numberWithCommas(parseFloat(dataProps.resume.descuExenta).toFixed(2))}}</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Monto global Des., Rebajas y otros a ventas gravadas</td>
                                                                <td>{{dataProps.header.invoiceMoney}}</td>
                                                                <td style="text-align: right; width: 50px;">{{numberWithCommas(parseFloat(dataProps.resume.descuGravada).toFixed(2))}}</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Impuesto al Valor Agregado 13%</td>
                                                                <td>{{dataProps.header.invoiceMoney}}</td>
                                                                <td style="text-align: right; width: 50px;">{{numberWithCommas(parseFloat(dataProps.resume.totalIva).toFixed(2))}}</td>
                                                            </tr>
                                                            <tr style="font-size: 12px;">
                                                                <td><b>Total</b></td>
                                                                <td><b>{{dataProps.header.invoiceMoney}}</b></td>
                                                                <td style="text-align: right; width: 50px;"><b>{{numberWithCommas(parseFloat(dataProps.resume.montoTotalOperacion).toFixed(2))}}</b></td>
                                                            </tr>
                                                        </template>
                                                    </table>
                                                </td>
                                            </tr>
                                            <template v-if="dataProps.header.InvoiceInternalNote"> 
                                                <tr> 
                                                    <td colspan="10" style="padding: 5px;">
                                                        <b>Observaciones: </b> {{ commentsOuterHtml }}
                                                    </td>
                                                </tr>
                                            </template>
                                        </table>
                                    </div>
                                </div>
                                
                                <div style="position: absolute; bottom: 0; width: 100%; left: 80px; text-align: left;">
                                    <p style="font-size:10px;">Pagina {{ indexPage+1 }} de {{ numPages }}</p>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
        </div>
    </div>
</template>

<script>

import jsPDF from 'jspdf'
import QRCode from 'qrcode';
//import html2canvas from "html2canvas"
//import WindowPortal from '@/components/WindowPortal.vue'
//import { images3213715 } from '@/assets/images/imagesposfe/3213715/images.js'
import { addFormatToNIT, addFormatNRC } from '@/helpers/misc'
import { numberWithCommas } from '@/helpers/money';
import {numberToText} from '@/helpers/conveterNumberText.js'

export default {
    name: "PrintPosFa",
    props: {
        dataProps: {
            type: Object,
        },
        hideQr: {
            type: Boolean,
            default: false
        },
        showInvoice: {
            type: Boolean,
            default: false
        },
    },
    components: {
    },
    data() {
        return {
            header: null,
            footer: null,
            loading: false,
            //seal: "",
            //sealBranch: "",
            numPages: 0,
            //announcement3213715: images3213715.announcement,
            mailLabel: null,
            valuesRows: [],
            urlQr: null,
            typePrint: 0,
            //specialinvoicescolor: JSON.parse( localStorage.getItem( "user" ) ).specialinvoicescolor,
            //kitcoalternativeposclientdata: JSON.parse(localStorage.getItem("user")).kitcoalternativeposclientdata,
        }
    },
    mounted() {
        console.log(this.dataProps);
        setTimeout(async () => {
            await this.calculatePagesOfDoc()
            const dateToArray = this.dataProps.header.invoiceDate.split("/");
            const day = dateToArray[0];
            const month = dateToArray[1];
            const year = dateToArray[2];

            // Crear una nueva fecha en formato "yyyy-mm-dd"
            const newDate = `${year}-${month}-${day}`;
            const url = `https://admin.factura.gob.sv/consultaPublica?ambiente=${this.dataProps.header.invoiceMHEnv}&codGen=${this.dataProps.header.invoiceCodGen}&fechaEmi=${newDate}`;
            
            // Generar el código QR y mostrarlo en el elemento con id "qrcode"

            const opt = {
                errorCorrectionLevel: 'H',
                type: 'image/png',
                quality: 0.3,
                margin: 1,
                color: {
                    dark:"#000000",
                    light:"#ffffff"
                },
                width: 100
            }

            // QRCode.toCanvas(this.$refs.qrcode, url, { width: 100 }, (error) => {
            //     if (error) {
            //         console.log('Error al generar el código QR:', error);
            //     }
            // });
            
            QRCode.toDataURL(url, opt, (error, url) => {
                if (error) {
                    console.log('Error al generar el código QR:', error);
                    throw error;
                }

                //console.log(url);
                this.urlQr = url
                //this.$refs.qrcode.src = url;
            });
            // await this.getImages()
        }, 0);
        //console.log(this.dataProps);
        //console.log(this.dataProps.branch)
        //console.log(this.dataProps.dteJson)
        //this.seal = JSON.parse(localStorage.getItem("user")).seal || ""
        //this.sealBranch = JSON.parse(localStorage.getItem("user")).sealBranch || ""
        //this.getHeaderFooter()
        // if(this.dataProps.)
        // this.$nextTick(() => {
        //     this.sendMail();
        // })
    },
    computed: {
        BillingNrc() {
            return addFormatNRC(this.dataProps.header.businessNRC)
        },
        BillingNit() {
            return addFormatToNIT(this.dataProps.header.businessNIT)
        },
        pages() {
            // Generar un arreglo con `numPages` elementos
            return Array.from({ length: this.numPages });
        },
        commentsOuterHtml() {
            return this.dataProps.header.InvoiceInternalNote.replace(/<[^>]*>/g, '')
        },
        allowedLogoBusiness() {
            if(this.dataProps.header.requesterKey == '2d72fd5378cc1e01261128d41f40a22f5d47444926d60c94') {
                return true;
            }
            else {
                return false;
            }
        }
    },
    /* watch: {
        dataProps: {
            deep: true,
            immediate: true,
            handler(newVal) {
                console.log(newVal);
            }
        } 
    }, */
    methods: {
        GetnumberToText(number) {
            if(number) return numberToText(number)
            else return ''
        },
        numberWithCommas(x) {
            return numberWithCommas(x);
        },
        /* getHeaderFooter() {
            let search = {};
            search.ID_business = JSON.parse(localStorage.getItem("user")).businessid || ""
            search.ID_branch = JSON.parse(localStorage.getItem("user")).branch || ""
            this.$API.HF.getHF(search).then((dataProps) => {
                let header = [], footer = [];
                header = dataProps.filter((x) => x.State == '1' && x.Type == 'Header');
                this.header = header.length > 0 ? JSON.parse(header[0].Body).html : '';
                this.header = this.header.replace(/(\n)/gm, "");
                footer = dataProps.filter((x) => x.State == '1' && x.Type == 'Footer');
                this.footer = footer.length > 0 ? JSON.parse(footer[0].Body).html : '';
            })
        }, */

        calculatePagesOfDoc() {
            let pages = 0
            let rows = 0
            this.valuesRows[pages] = []

            try{
                if(this.dataProps.header.businessNRC == '3213715' || this.dataProps.header.businessNRC == '2201050') {
                    this.typePrint = 1
                    //One page
                    if(this.dataProps.body.length <= 24){
                        for(let i = 0; i < this.dataProps.body.length; i++){
                            rows++
                            this.valuesRows[pages].push(this.dataProps.body[i])
                        }    
                    }
                    else{
                        for(let i = 0; i < this.dataProps.body.length; i++){
                            if(rows < 29 && (i !== this.dataProps.body.length - 1)){
                                rows++
                                this.valuesRows[pages].push(this.dataProps.body[i])
                            }
                            else if(rows <= 24){
                                rows++
                                this.valuesRows[pages].push(this.dataProps.body[i])
                            }
                            else{
                                this.valuesRows[pages].push(this.dataProps.body[i])
                                pages++
                                rows = 0
                                this.valuesRows[pages] = []
                            }
                        }
                    }
                }
                else {
                    this.typePrint = 0
                    //Cuando es solo una página
                    if(this.dataProps.body.length <= 34){
                        for(let i = 0; i < this.dataProps.body.length; i++){
                            rows++
                            this.valuesRows[pages].push(this.dataProps.body[i])
                        }    
                    }
                    else {
                        for(let i = 0; i < this.dataProps.body.length; i++){
                            if(rows < 39 && (i !== this.dataProps.body.length - 1)){
                                rows++
                                this.valuesRows[pages].push(this.dataProps.body[i])
                            }
                            else if(rows <= 34){
                                rows++
                                this.valuesRows[pages].push(this.dataProps.body[i])
                            }
                            else{
                                this.valuesRows[pages].push(this.dataProps.body[i])
                                pages++
                                rows = 0
                                this.valuesRows[pages] = []
                            }
                        }
                    }
                }
                
            }
            catch(err) {
                alert(err)
            }
            pages = pages + 1;
            this.numPages = pages;
        },

        async Print(){
            this.loading = true;
            var doc = new jsPDF('p', 'pt', 'letter')
            await doc.html(this.$refs.dataprint, {
                callback: function(doc){
                    doc.output()
                },
                x: 0,
                y: 0,
                margin: [0, 0, 0, 0],
                html2canvas:{ 
                    scale: 0.612,
                    //scale: (doc.internal.pageSize.width - 10 * 2) / this.$refs.dataprint.scrollWidth,
                    useCORS: true,
                    logging: false,
                    allowTaint: true
                },
            })
            
            
            //doc.deletePage(2)

            doc.autoPrint({variant: 'non-conform'});
            const blob = doc.output("bloburl");
            window.open(blob);

            this.loading = false;
        },

        async Download(){
            this.loading = true;
            var doc = new jsPDF('p', 'pt', 'letter')
            await doc.html(this.$refs.dataprint, {
                callback: function(doc){
                    doc.output()
                },
                x: 0,
                y: 0,
                margin: [0, 0, 0, 0],
                html2canvas: { 
                    scale: 0.612,
                    //scale: (doc.internal.pageSize.width - 10 * 2) / this.$refs.dataprint.scrollWidth,
                    useCORS: true,
                    logging: false,
                    allowTaint: true
                },
            });
            
            //doc.deletePage(2)
            doc.save(this.dataProps.header.invoiceID + "_" + this.dataProps.header.invoiceDate.replaceAll("/","-") + "_" + this.dataProps.header.invoiceName.replaceAll(/[:/<>*|/]/g, "").replaceAll('\\"','') + ".pdf"); //Agregado por Juan 13082023

            this.loading = false;
        },
        async sendMail() {
            try {
                this.mailLabel = null;
                this.mailLabel = 'Enviando...';
                var doc = new jsPDF('p', 'pt', 'letter')
                //console.log(doc);
                //console.log(this.$refs.dataprint);
                await doc.html(this.$refs.dataprint, {
                    callback: function(doc){
                        doc.output()
                    },
                    x: 0,
                    y: 0,
                    margin: [0, 0, 0, 0],
                    html2canvas:{ 
                        scale: 0.612,
                        //scale: (doc.internal.pageSize.width - 10 * 2) / this.$refs.dataprint.scrollWidth,
                        useCORS: true,
                        logging: false,
                        allowTaint: true
                    },
                });
                
                //this.assignCuerpoDocumento();

                //const { dataProps: { header: { jsonMH }, dteJson: { ID_Invoice } } } = this.dataProps;

                const body = {
                    dtefilebuffer: new Buffer.from(doc.output('arraybuffer')),
                    dtejson: { 
                        ID_Invoice: this.dataProps.header.invoiceID,
                        header: this.dataProps.header,
                        body: this.dataProps.body,
                        resume: this.dataProps.resume
                    },
                    dtefilebufferfilename: this.dataProps.header.invoiceID + "_" + this.dataProps.header.invoiceDate.replaceAll("/","-") + "_" + this.dataProps.header.invoiceName.replaceAll(/[:/<>*|/]/g, "").replaceAll('\\"','') + ".pdf",
                };

                console.log(Buffer);
                console.log(body);
                //await this.$API.invoices.sendInvoiceMail(body);

                this.mailLabel = 'Se envió el correo correctamente';
                this.$emit('mailSent');
            } catch (error) {
                this.mailLabel = `Ocurrió el siguiente error al enviar el correo ${ error }`;
                // console.log(error);
            }
        },
        assignCuerpoDocumento() {
            /* this.dataProps.header.jsonMH.cuerpoDocumento = this.dataProps.body.map( ( item, index ) => ( {
                "descripcion": item.ItemDescription,
                "montoDescu": item.ItemDiscount,
                "codigo": item.ID_Item,
                "ventaGravada": item.ItemGravada,
                "ivaItem": item.ItemIva,
                "ventaNoSuj": item.ItemNoSuj,
                "ventaExenta": item.ItemExenta,
                // "tributos": null,
                "numItem": index + 1,
                "noGravado": item.ItemNoGravada,
                "psv": item.ItemPsv,
                // "tipoItem": 3,
                // "codTributo": null,
                "uniMedida": item.ItemMeasureUnit,
                // "numeroDocumento": null,
                "cantidad": item.ItemQuantity,
                "precioUni": item.ItemUnitPrice
            } ) ) */
            // for( const item in this.dataProps.body ) {
            //      [
            //         "descr"
            //     ]
            // }
        },
        async downLoadJSON() {
            try {
                const aElement = document.createElement('a');
                aElement.setAttribute('download', this.dataProps.header.invoiceID + "_" + this.dataProps.header.invoiceDate.replaceAll("/","-") + "_" + this.dataProps.header.invoiceName.replaceAll(/[:/<>*|/]/g, "").replaceAll('\\"','') + ".pdf");
                //this.assignCuerpoDocumento();
                const blob = new Blob([JSON.stringify(this.dataProps.jsonToExport)], { type: 'application/json' });
                const href = URL.createObjectURL(blob);
                aElement.href = href;
                aElement.download = this.dataProps.header.invoiceID + "_" + this.dataProps.header.invoiceDate.replaceAll("/","-") + "_" + this.dataProps.header.invoiceName.replaceAll(/[:/<>*|/]/g, "").replaceAll('\\"','') + ".json";
                aElement.click();
                URL.revokeObjectURL(href);
            } catch (error) {
                this.mailLabel = 'Ocurrió un error al descargar JSON';
                console.log(error);
            }
        },
        /* async getImages() {
            const img = this.dataProps.branch.BranchBannerPath
            const { imageBase64: logoImageBase64 } = await this.$API.utilities.getImageInBase64({ "file": img })
            //this.dataProps.branch.BranchBannerPath = `${logoImageBase64}`  
            console.log(logoImageBase64);
        }, */
    }
}
</script>

<style scoped>
    .u-row .u-col-100{
        width: 100% !important;
    }
    /* .btnclean{
        border-radius: 15px;
        border: 1px solid white;
        background-color: #F29D35 !important;
        color: white !important;
    } */
    .btnclose{
        border-radius: 15px;
        border: 1px solid white;
        background-color: rgb(255, 38, 38) !important;
        color: white !important;
       
    }

    .btn-action {
        background-image: linear-gradient(#f7f8fa ,#e7e9ec);
        border-color: #adb1b8 #a2a6ac #8d9096;
        border-style: solid;
        border-width: 1px;
        border-radius: 3px;
        /* box-shadow: rgba(255,255,255,.6) 0 1px 0 inset; */
        box-sizing: border-box;
        color: #0f1111;
        cursor: pointer;
        display: inline-block;
        /* font-family: "Amazon Ember",Arial,sans-serif;
        font-size: 14px; */
        height: 30px;
        overflow: hidden;
        padding: 0 11px;
        text-align: center;
        text-decoration: none;
        text-overflow: ellipsis;
        user-select: none;
        -webkit-user-select: none;
        touch-action: manipulation;
        white-space: nowrap;
        transition: all .2s ease-in-out;

    }
    
    .btn-action:active {
        border-bottom-color: #a2a6ac;
    }

    .btn-action:hover {
        background-image: linear-gradient(#cdcdcd ,#e3e2e2);
    }

    * {
        font-family: Arial, Helvetica, sans-serif !important;
    }

</style>
